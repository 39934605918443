@import '../../assets/scss/_master';

.c-notes {
    height: 100%;
    background-color: #9E9E9E;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.7vw;
	}
	&::-webkit-scrollbar-track {
        background-clip: content-box;
	}
	&::-webkit-scrollbar-thumb {
		background: #E8E8E8;
		border: 1px solid #B6B6B6;
        border-radius: 1vw;
	}
}

.c-create-notes {
    padding: 2.5vw;
}

.c-create-notes__header {
    background-color: #263646;
    height: 3vw;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 1vw;
    border-radius: 0.625vw 0.625vw 0vw 0vw;
}

.create-notes__header-left {
    padding: 1vw;
}

.create-notes__header-right {
    padding: 1vw;
    cursor: pointer;
}

.c-create-notes__body {
    height: 12vw;
    border-radius: 0 0 0.625vw 0.625vw;
    background-color: #DBEAEF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;  
}

.create-notes__body-textarea {
    resize: none;
    width: 100%;
    background-color: #DBEAEF;
    height: 12vw;
    padding: 1vw;

    &::-webkit-scrollbar {
		width: 0.7vw;
	}
	&::-webkit-scrollbar-track {
		background-clip: content-box;
	}
	&::-webkit-scrollbar-thumb {
		background: #E8E8E8;
		border: 1px solid #B6B6B6;
		border-radius: 1vw;
	}
}

.c-view-notes {

}