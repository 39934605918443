.c-editor {
	height: 100%;
	border:1px solid #828282;
	flex-direction: column;
}

.c-editor__ace {
	height: 80%;
}

:global {
	.readonly-highlight {
		background-color: grey;
		opacity: 0.2;
		position: absolute;
	}
	
	.ace_content {
		background-color: rgba(0,0,0,0.00);
	}
}

