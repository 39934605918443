@import '../../assets/scss/_master';

.c-feedback {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
}

.c-feedback__notes {
    margin-top: 1vw;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
    height: 31.2vw;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        width: 0.7vw;
	}
	&::-webkit-scrollbar-track {
        background-clip: content-box;
	}
	&::-webkit-scrollbar-thumb {
		background: #E8E8E8;
		border: 1px solid #B6B6B6;
        border-radius: 1vw;
	}
}